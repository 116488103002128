<template>
  <div id="personalCenter">
    <!-- 个人中心侧栏 -->
    <div class="personalLeft">
      <div class="personalLtop">
        <img :src="avatar" class="headImg" />
        <div>{{ jobNumber }}</div>
        <el-button @click="loginOut">{{ t('639') }}</el-button>
      </div>
      <div class="personalLbottom">
        <el-menu
          router
          text-color="#FFFFFF"
          active-text-color="#00F5FF"
          background-color="@views-bg"
        >
          <el-menu-item
            v-for="item in menuList"
            :key="item.path"
            :index="item.path"
            @click="changeMenu(item.path)"
            :class="activePath === item.path ? 'activeMenu' : ''"
          >
            <img
              class="menuImg"
              :src="activePath === item.path ? item.activeSrc : item.src"
            />
            <template #title>
              <span style="font-size: 1.125rem">{{ item.title }}</span>
              <!-- <img v-if="item.title === '飞行证书'" class="errorImg" src="@/asset/img/personal-icon/error.png"> -->
            </template>
          </el-menu-item>
        </el-menu>
      </div>
    </div>

    <!-- 右边信息栏 -->
    <div class="personalContent">
      <router-view @getUserinformation="getUserinformation"></router-view>
    </div>
  </div>
</template>
<script lang="">
import { t } from '../../languages';
import { reactive, toRefs, defineComponent, provide, onMounted } from "vue";
import { useRouter } from "vue-router";
import userService from "@/network/user";
import { ElMessage } from "element-plus/lib/components";
import { store } from "@/store";
export default defineComponent({
  setup() {
    const router = useRouter();
    let data = reactive({
      activePath: router.currentRoute.value.fullPath, //获取刷新或进入后的路由路径
      /* 个人中心的信息菜单 */
      menuList: [
        {
          path: "/useAccount",
          activeSrc: require("@/asset/img/personal-icon/useAccount1.png"),
          src: require("@/asset/img/personal-icon/useAccount.png"),
          title: t("640"),
        },
        {
          path: "/changePassword",
          activeSrc: require("@/asset/img/personal-icon/passWord1.png"),
          src: require("@/asset/img/personal-icon/passWord.png"),
          title: t("641"),
        },
        {
          path: "/flyCertificate",
          activeSrc: require("@/asset/img/personal-icon/flyCertificate1.png"),
          src: require("@/asset/img/personal-icon/flyCertificate.png"),
          title: t("642"),
        },
      ],
      avatar: "", //头像
      jobNumber: "", //工号
      id: "",
    });

    /* 提供子组件上传证书的跳转函数 */
    const updateActivepath = () => {
      router.push("/flyCertificate");
      data.activePath = "/flyCertificate";
    };
    provide("activePath", updateActivepath);

    /**
     * 切换个人中心的子菜单
     * @param index --路由路径，用于效果切换
     */
    function changeMenu(index) {
      data.activePath = index;
    }

    function getUserinformation(value) {
      data.avatar = value.avatar;
      data.jobNumber = value.jobNumber;
    }
    /* 退出登录 */
    function loginOut() {
      userService.loginOut().then((res) => {
        if (res.resultStatus) {
          store.commit("removeUserInfo");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("menusId");
          localStorage.removeItem("token");
          router.replace("/");
          ElMessage.success(t("643"));
        }
      });
    }
    onMounted(() => {
      const userInfo =
        store.state.user.userInfo || localStorage.getItem("userInfo");
      data.id = userInfo.id;
      //防止刷新丢失信息，重新请求
      if (data.activePath !== "/useAccount") {
        userService.getUserinformation(data.id).then(
          (res) => {
            data.jobNumber = res.resultData.jobNumber;
            data.avatar = res.resultData.avatar;
          },
          (err) => {
            ElMessage.error(t("644"));
            console.log(err);
          }
        );
      }
    });

    return {
      ...toRefs(data),
      changeMenu,
      getUserinformation,
      loginOut,
    };
  },
});
</script>

<style lang="less" scoped>
#personalCenter {
  height: 100%;
  color: #ffffff;
  display: flex;
  background: rgba(0, 28, 49, 0.8);
  padding: 1.1875rem;
  /* 左侧栏 */
  .personalLeft {
    margin-right: 1.1875rem;
    width: 21.25rem;
    text-align: center;
    overflow: hidden;
    .personalLtop {
      background-color: @views-bg;
      margin-bottom: 1.0625rem;
    }
    .personalLbottom {
      background-color: @views-bg;
      padding-top: 1.375rem;
      height: 100%;
      overflow: auto;
    }
    /* 头像样式 */
    .headImg {
      width: 9.8rem;
      height: 9.8125rem;
      border-radius: 50%;
      margin-bottom: 1.4375rem;
      margin-top: 3.5rem;
    }
    .el-button {
      height: 2.875rem;
      width: 8.4375rem;
      margin-top: 3.75rem;
      background: rgba(0, 28, 49, 0.7);
      margin-bottom: 2.8125rem;
      border: 1px solid #00f5ff;
      color: #ffffff;
    }
    .menuImg {
      width: 1.5rem;
      height: auto;
      margin: 1.0625rem 5.25rem 1rem 0.3125rem;
    }
    .el-menu {
      border-right: 0;
    }
    .el-menu-item:hover {
      background-color: #005ca0;
    }
    .errorImg {
      position: absolute;
      right: 1.625rem;
    }
    /* 菜单点击选中效果 */
    .activeMenu {
      background-color: #005ca0;
      &:after {
        content: "";
        position: absolute;
        display: inline-block;
        width: 0.1875rem;
        height: 1.5rem;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        background: #00f5ff;
      }
    }
  }
  /* 右侧栏样式 */
  .personalContent {
    background-color: @views-bg;
    width: 55.5625rem;
    overflow: auto;
  }
}
</style>
